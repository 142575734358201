"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = CertificateItem;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const DateUtils_1 = require("../../scripts/utils/DateUtils");
function CertificateItem({ issueDate, issuer, name, url }) {
    return (React.createElement(react_bootstrap_1.Card, null,
        React.createElement(react_bootstrap_1.Card.Body, { className: "d-flex flex-column" },
            React.createElement("div", { className: "justify-content-center mt-2" },
                React.createElement(react_bootstrap_1.Card.Img, { src: issuer.img })),
            React.createElement(react_bootstrap_1.Card.Title, null,
                React.createElement(react_bootstrap_1.Card.Link, { target: "_blank", rel: "noopener noreferrer", href: url }, name)),
            React.createElement(react_bootstrap_1.Card.Subtitle, null,
                "Issuer: ",
                issuer.name)),
        React.createElement(react_bootstrap_1.Card.Footer, null, (0, DateUtils_1.getDateLocale)(issueDate.getFullYear(), issueDate.getMonth()))));
}
