"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = FooterEmail;
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
function FooterEmail() {
    return (React.createElement(react_bootstrap_1.Col, null,
        React.createElement("i", { className: "fa fa-envelope fa-2x" }),
        React.createElement("p", null,
            React.createElement("a", { target: "_blank", rel: "noreferrer", href: "mailto:fabasoad@gmail.com" }, "fabasoad@gmail.com"))));
}
