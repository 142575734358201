"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_bootstrap_1 = require("react-bootstrap");
const TechnologyStorage_1 = require("../../scripts/technologies/TechnologyStorage");
const StatsCommon = ({ techName, url, children }) => {
    const technologyStorage = new TechnologyStorage_1.default();
    const tech = technologyStorage.findByName(techName);
    return (React.createElement(react_bootstrap_1.Row, { className: "justify-content-center" },
        React.createElement("a", { href: url, target: "_blank", rel: "noreferrer", className: "text-center" },
            React.createElement("img", { src: tech.img, alt: tech.name, title: tech.title })),
        children));
};
exports.default = StatsCommon;
