"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const DateDuration_1 = require("./DateDuration");
const DateTimeline_1 = require("./DateTimeline");
function DateCircleBox({ fromMonthIndex, fromYear, toMonthIndex, toYear }) {
    return (React.createElement("div", { className: "timeline-image" },
        React.createElement(DateDuration_1.default, { fromMonthIndex: fromMonthIndex, fromYear: fromYear, toMonthIndex: toMonthIndex, toYear: toYear }),
        React.createElement(DateTimeline_1.default, { fromMonthIndex: fromMonthIndex, fromYear: fromYear, toMonthIndex: toMonthIndex, toYear: toYear })));
}
exports.default = DateCircleBox;
