"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GitHubService = void 0;
const rest_1 = require("@octokit/rest");
const State_1 = require("../../components/Stats/State");
const cross_fetch_1 = require("cross-fetch");
class GitHubService {
    state = State_1.default.NOT_STARTED;
    starsAmount;
    static GITHUB_USERNAME = 'fabasoad';
    constructor(starsAmount) {
        this.starsAmount = starsAmount;
    }
    async request() {
        if (this.state == State_1.default.NOT_STARTED) {
            this.state = State_1.default.STARTED;
            const octokit = new rest_1.Octokit({ request: { fetch: cross_fetch_1.default } });
            try {
                let sum = 0;
                let page = 1;
                const perPage = 100;
                let length = perPage;
                while (length === perPage) {
                    const { data } = await octokit.rest.repos.listForUser({
                        username: GitHubService.GITHUB_USERNAME,
                        page,
                        per_page: perPage
                    });
                    length = data.length;
                    for (let i = 0; i < data.length; i++) {
                        sum += data[i]['stargazers_count'];
                    }
                    page++;
                }
                this.state = State_1.default.FINISHED;
                this.starsAmount = sum;
            }
            catch (err) {
                this.state = State_1.default.FAILED;
            }
        }
        return this.starsAmount;
    }
}
exports.GitHubService = GitHubService;
