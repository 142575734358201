"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = EducationItem;
const React = require("react");
const react_i18next_1 = require("react-i18next");
const DateCircleBox_1 = require("../Controls/DateCircleBox");
function EducationItem({ from, to, title }) {
    const { t } = (0, react_i18next_1.useTranslation)();
    return (React.createElement(React.Fragment, null,
        React.createElement(DateCircleBox_1.default, { fromMonthIndex: 8, fromYear: from, toMonthIndex: 8, toYear: to }),
        React.createElement("div", { className: "timeline-panel" },
            React.createElement("div", { className: "timeline-heading" },
                React.createElement("h4", null, t('business-card-education-university-title')),
                React.createElement("h4", { className: "subheading" }, title)),
            React.createElement("div", { className: "timeline-body" },
                React.createElement("p", null, t('business-card-education-university-speciality'))))));
}
